import React from 'react';
import MainLayout from '../layouts/main';
import { Link } from 'gatsby';
import { Typography } from '@material-ui/core';
import { SEO } from '../components/SEO';
import {
  SignupButton,
  SignupButtonWithChoice
} from '../components/SignupButton';
import PageHead from '../components/PageHead';
import { SalesCallout } from '../components/SalesCallout';
import styled from '../styled';
import { ResponsiveImage } from '../components/ResponsiveImage';

export const Highlight = styled('span')`
  color: ${(p) => p.theme.palette.primary.main};
  font-weight: 700;
  background-color: #69c0ff29;
  padding: 5px;
  border-radius: 3px;
`;

const Outer = styled('div')`
  background-color: #f5f7f9;
  margin: 24px;
  padding: ${(p) => p.theme.spacing(6)}px ${(p) => p.theme.spacing(2)}px;

  @media (max-width: 800px) {
    padding: ${(p) => p.theme.spacing(6)}px ${(p) => p.theme.spacing(3)}px;
  }
`;

const Inner = styled('div')`
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-column-gap: ${(p) => p.theme.spacing(4)}px;
  max-width: 1000px;
  border-radius: 8px;
  margin: 0 auto;

  @media (max-width: 800px) {
    grid-template-columns: 1fr;
    grid-row-gap: ${(p) => p.theme.spacing(2)}px;
  }

  img {
    border: 1px solid #ddd;
    filter: drop-shadow(0px 0px 7px rgba(0, 0, 0, 0.06));
    display: inline-block;
    width: 100px;
    border-radius: 100%;
    margin-bottom: ${(p) => p.theme.spacing(3)}px;
  }

  a {
    border-bottom: 1px dashed;
  }
`;

export default () => {
  return (
    <MainLayout>
      <div>
        <SEO
          pathname="/reviews/"
          title="Affilimate Reviews: What Customers Are Saying"
          description=""
        />
        <PageHead>
          <Typography
            variant="h3"
            gutterBottom
            component="h1"
            style={{
              maxWidth: '600px',
              margin: '0 auto 36px',
              fontWeight: 700
            }}
          >
            Affilimate Reviews: What Our Customers Are Saying
          </Typography>
          <Typography
            variant="body1"
            color="textSecondary"
            style={{ maxWidth: '510px', margin: '24px auto 12px' }}
          >
            These savvy, data-driven marketers are creating optimized, commerce
            content that pays for itself year after year.
          </Typography>
        </PageHead>
        <Outer>
          <Inner>
            <div>
              <ResponsiveImage
                src="/images/testimonials/marketinglogiq.png"
                alt="Marketing LogIQ"
                width="255"
                height="39"
                style={{ border: 0, borderRadius: 0, width: '255px' }}
              />
              <Typography
                variant="body1"
                component="h2"
                style={{ fontWeight: 'bold' }}
              >
                Marketing LogIQ
              </Typography>
              <Typography
                variant="body2"
                component="p"
                color="textSecondary"
                paragraph
              >
                Josh, Founder of{' '}
                <a href="https://marketinglogiq.com/" target="_blank">
                  Marketing LogIQ
                </a>
              </Typography>
              <Typography variant="caption" component="p" color="textSecondary">
                Digital marketer with close to a decade of experience running
                online businesses.
              </Typography>
            </div>
            <div>
              <Typography variant="body1" component="p" paragraph>
                Affilimate makes it easy to track earnings across multiple sites
                and networks in one{' '}
                <Link to="/product/affiliate-dashboard/">
                  convenient dashboard
                </Link>
                . If you are logging into dozens of affiliate accounts to track
                your performance, you are wasting your time. Affilimate makes it
                easy to track performance with a few clicks.
              </Typography>
              <Typography variant="body1" component="p" paragraph>
                Most affiliate marketers utilize Google Analytics to track
                performance.
              </Typography>
              <Typography variant="body1" component="p" paragraph>
                While Google Analytics provides great insights, it is missing
                the most salient metric for any online marketer – revenue.
              </Typography>
              <Typography variant="body1" component="p" paragraph>
                Who cares about pageviews? We’re in this game to make money.
              </Typography>
              <Typography variant="body1" component="p" paragraph>
                <strong>
                  Affilimate allows you to track revenue generation the same way
                  Google Analytics allows you to track site visitors.
                </strong>
              </Typography>
              <Typography variant="body1" component="p" paragraph>
                Affilimate enables affiliate marketers to track performance at
                the page-level. This is huge, especially when you manage
                multiple sites and multiple affiliate offers. For example, if
                you promote the same offer across multiple pages, don’t you want
                to know which pages are generating the best results?
              </Typography>
              <Typography variant="body1" component="p" paragraph>
                Affilimate helps you squeeze every last penny out of your
                affiliate marketing efforts.
              </Typography>
              <Typography variant="body1" component="p" paragraph>
                The insights available in the Content Analytics cannot be
                oversold. If you comb through this data, you will find
                opportunities to increase revenue."
              </Typography>
              <Typography
                color="textSecondary"
                variant="body2"
                component="p"
                paragraph
              >
                <a
                  href="https://marketinglogiq.com/affilimate-review/"
                  target="_blank"
                >
                  Read more
                </a>
              </Typography>
            </div>
          </Inner>
        </Outer>
        <Outer>
          <Inner>
            <div>
              <ResponsiveImage
                src="/images/testimonials/lia-garcia.png"
                alt="Lia Garcia"
                width="100"
                height="100"
              />
              <Typography
                variant="body1"
                component="h3"
                style={{ fontWeight: 'bold' }}
              >
                Lia Garcia
              </Typography>
              <Typography
                variant="body2"
                component="p"
                color="textSecondary"
                paragraph
              >
                Founder of{' '}
                <a href="https://practicalwanderlust.com/" target="_blank">
                  Practical Wanderlust
                </a>
              </Typography>
              <Typography variant="caption" component="p" color="textSecondary">
                Featured in Lonely Planet, The Washington Post, CNN, Vice,
                Buzzfeed, and more.
              </Typography>
            </div>
            <div>
              <Typography variant="body1" component="p" paragraph>
                <strong>
                  Affilimate is a powerful{' '}
                  <Link to="/product/affiliate-dashboard/">
                    affiliate marketing dashboard
                  </Link>
                </strong>
                . At a glance, you’ll be able to combine reporting from multiple
                different affiliate platforms – something we used to have to do
                manually on Excel (ugh).
              </Typography>
              <Typography variant="body1" component="p" paragraph>
                You’ll also get vastly improved analytics and insights into
                which blog posts, programs, and products perform the best. They
                even have heat maps!
              </Typography>
              <Typography variant="body1" component="p" paragraph>
                We also love Affilimate’s{' '}
                <Link to="/product/link-management/">
                  Amazon broken link checker tool
                </Link>
                , which we have set to run automatically every week to let us
                know which Amazon products are sold out, out of stock, or have
                mysteriously vanished.
              </Typography>
              <Typography variant="body1" component="p" paragraph>
                Affilimate was created by someone who deeply understands how
                frustrating affiliate marketing can be. As someone who loves
                data (in my pre-blogging life, I was a business systems analyst,
                so I like … really nerdy analytics stuff), I’m a huge fan!
              </Typography>
              <Typography
                color="textSecondary"
                variant="body2"
                component="p"
                paragraph
              >
                <a
                  href="https://practicalwanderlust.com/how-to-make-money-blogging/"
                  target="_blank"
                >
                  Read more
                </a>
              </Typography>
            </div>
          </Inner>
        </Outer>
        <Outer>
          <Inner>
            <div>
              <ResponsiveImage
                src="/images/testimonials/jessica-bishop.jpeg"
                alt="Jessica Bishop"
                width="100"
                height="100"
              />
              <Typography
                variant="body1"
                component="h2"
                style={{ fontWeight: 'bold' }}
              >
                Jessica Bishop
              </Typography>
              <Typography
                variant="body2"
                component="p"
                color="textSecondary"
                paragraph
              >
                Founder of{' '}
                <a href="https://thebudgetsavvybride.com" target="_blank">
                  The Budget Savvy Bride
                </a>
              </Typography>
              <Typography variant="caption" component="p" color="textSecondary">
                Featured on Good Morning America, BRIDES, Glamour, Cosmopolitan,
                Huffington Post, Refinery 29, and more.
              </Typography>
            </div>
            <div>
              <Typography variant="body1" component="p" paragraph>
                "I recommend signing up for Affilimate to keep track of all your
                conversions and earnings in one easy dashboard. Talk about a
                game-changer!
              </Typography>
              <Typography variant="body1" component="p" paragraph>
                It’s super important to track the performance of your promotions
                to see what’s resonating most with your audience. Affilimate
                comes with a 15-day free trial so you can give it a spin and
                test it out yourself and see the awesome insights you get.
              </Typography>
              <Typography variant="body1" component="p" paragraph>
                <strong>
                  Affilimate gives you deep levels of data so you can analyze
                  and optimize your best-performing content.
                </strong>{' '}
                This makes improving your site, your content, and your earnings
                much easier. I couldn’t recommend it enough!"
              </Typography>
              <Typography
                color="textSecondary"
                variant="body2"
                component="p"
                paragraph
              >
                <a
                  href="https://thebudgetsavvybride.com/affiliate-marketing-bloggers/#affiliate-dashboard"
                  target="_blank"
                >
                  Read more
                </a>
              </Typography>
            </div>
          </Inner>
        </Outer>
        <SalesCallout />
      </div>
    </MainLayout>
  );
};
